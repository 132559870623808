import { defineStore } from "pinia";

interface IPreloadState {
  searchList: string[];
  bannerList: any[];
  hotGameList: any[];
  updateList: any[];
  tagList: any[];
  regionList: IRegionItem[];
  autoExec: boolean;
  closeStatus: number;
}

export const usePreloadStore = defineStore({
  id: "app-preload",
  state: (): IPreloadState => ({
    searchList: [],
    bannerList: [],
    hotGameList: [],
    updateList: [],
    tagList: [],
    regionList: [],
    autoExec: false,
    closeStatus: 0,
  }),
  persist: {
    key: "steampay-app-PreloadStore",
    storage: localStorage,
  },
  actions: {
    setAutoExec(b: boolean) {
      this.autoExec = b;
    },
    setCloseStatus(n: number) {
      this.closeStatus = n;
    },
    setSearchList(arr: any) {
      this.searchList = arr.slice(0, 10);
    },
    setBannerList(arr: any) {
      this.bannerList = arr;
    },
    setHotGameList(arr: any) {
      this.hotGameList = arr;
    },
    setUpdateList(arr: any) {
      this.updateList = arr;
    },
    setTagList(arr: any) {
      this.tagList = arr;
    },
    setRegionList(arr: any) {
      this.regionList = arr;
    },
  },
});

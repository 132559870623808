<template>
  <van-tabbar v-model="active" class="custom-tabbar van-hairline--top">
    <van-tabbar-item
      v-for="(item, index) in list"
      :key="index"
      icon="search"
      replace
      :to="item.path"
    >
      <template #icon="prop">
        <img
          class="op-40"
          :class="{ '!op-100': prop.active }"
          :src="item.icon"
        />
      </template>
      <span>{{ item.name }}</span>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script setup lang="ts">
import { getImgUrl } from "@/utils/Util";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const list = [
  {
    name: "首页",
    icon: getImgUrl("common/tab-1.png"),
    path: "/home",
  },
  {
    name: "商城",
    icon: getImgUrl("common/tab-2.png"),
    path: "/shop",
  },
  {
    name: "交易",
    icon: getImgUrl("common/tab-3.png"),
    path: "/community",
  },
  {
    name: "我的",
    icon: getImgUrl("common/tab-4.png"),
    path: "/mine",
  },
];
const active = ref(0);

watch(route, (to) => {
  const navBarActive = to.path;
  for (let i = 0; i < list.length; i++) {
    if (navBarActive.includes(`${list[i].path}`)) {
      active.value = i;
    }
  }
});
</script>

<style lang="scss">
.custom-tabbar {
  height: 130px;
  background: #efeff1;
  z-index: 10;
  &.van-hairline--top:after {
    border-color: rgba(51, 51, 51, 0.08) !important;
  }
  .van-tabbar-item {
    color: rgba($color: #333, $alpha: 0.4);
    font-size: 24px;
    .van-tabbar-item__icon {
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .van-tabbar-item--active {
    background: none;
    color: #333;
  }
}
</style>

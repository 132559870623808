import { createApp } from "vue";
// import 'vant/lib/index.css';
import "vant/es/toast/style";
import "virtual:uno.css";
import "@/assets/styles/reset.css";
import App from "./App.vue";
import vconsole from "vconsole";
import store from "@/store";
import router from "./router";
import { Lazyload, Image as VanImage } from "vant";
import "@/utils/hybrid_html_uni.webview.1.5.5.js";
import "@/utils/flexible.js";
// import '@vant/touch-emulator';

declare global {
  interface Window {
    keyboardJS: any;
    Mousetrap: any;
  }
}

if (import.meta.env.VITE_APP_ENV !== "production") {
  new vconsole();
}

document.oncontextmenu = function (e) {
  e.preventDefault();
};

//阻止双指放大页面
document.documentElement.addEventListener("gesturestart", function (event) {
  event.preventDefault();
});

const app = createApp(App);

// 全局错误处理 上线后因html缓存导致文件加载问题
// const errorHandler = (error: Error, vm: any, info: string) => {
//   console.error("errorHandler", error, vm, info);
//   const fetchResourcesErrors = [
//     "Failed to fetch dynamically imported module",
//     "Importing a module script failed",
//   ];
//   if (
//     fetchResourcesErrors.some(
//       (item) => error?.message && error.message?.includes(item)
//     )
//   ) {
//     window.location.reload();
//   }
// };

// (app as any).config.errorHandler = errorHandler;

app.use(store);
app.use(router);
app.use(Lazyload, {
  lazyComponent: true,
});
app.use(VanImage);
app.mount("#app");

import { useGlobalStore } from "@/store/modules/global";

/**
 * 获取渠道
 * @returns 渠道名
 */
export const GetChannel = (): string => {
  return QueryString("channel") || localStorage.getItem("channel") || "master";
};

export function generateUniqueId() {
  // 检查localStorage是否已存储唯一标识符，如果没有则生成一个
  let clientId = localStorage.getItem("clientId");
  if (!clientId) {
    clientId = "id-" + Math.random().toString(36).substr(2, 16);
    localStorage.setItem("clientId", clientId);
  }
  return clientId;
}

export function getDevice() {
  // 获取设备类型
  let deviceManufacturer = "unknown";
  const userAgent = navigator.userAgent.toLowerCase();

  // 通过用户代理字符串来识别手机厂商
  if (userAgent.includes("iphone")) {
    deviceManufacturer = "Apple";
  } else if (userAgent.includes("huawei")) {
    deviceManufacturer = "Huawei";
  } else if (userAgent.includes("xiaomi")) {
    deviceManufacturer = "Xiaomi";
  } else if (userAgent.includes("vivo")) {
    deviceManufacturer = "Vivo";
  } else if (userAgent.includes("oppo")) {
    deviceManufacturer = "Oppo";
  } else if (userAgent.includes("samsung")) {
    deviceManufacturer = "Samsung";
  } else if (userAgent.includes("meizu")) {
    deviceManufacturer = "Meizu";
  } else if (userAgent.includes("smartisan")) {
    deviceManufacturer = "Smartisan";
  }
  return deviceManufacturer;
}

export function getDeviceInfo() {
  let webLog: any = {};
  let userAgent = navigator.userAgent;
  // 获取微信版本
  let m1 = userAgent.match(/MicroMessenger.*?(?= )/);
  if (m1 && m1.length > 0) {
    webLog.wechat = m1[0];
  }
  // 苹果手机
  if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
    // 获取设备名
    if (userAgent.includes("iPad")) {
      webLog.device = "iPad";
    } else {
      webLog.device = "iPhone";
    }
    // 获取操作系统版本
    m1 = userAgent.match(/iPhone OS .*?(?= )/);
    if (m1 && m1.length > 0) {
      webLog.system = m1[0];
    }
  }
  // 安卓手机
  if (userAgent.includes("Android")) {
    // 获取设备名
    m1 = userAgent.match(/Android.*; ?(.*(?= Build))/);
    if (m1 && m1.length > 1) {
      webLog.device = m1[1];
    }
    // 获取操作系统版本
    m1 = userAgent.match(/Android.*?(?=;)/);
    if (m1 && m1.length > 0) {
      webLog.system = m1[0];
    }
  }
  return webLog;
}

export function getBrowserName() {
  let browserType = "unknown";
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.match(/MicroMessenger/i)) {
    browserType = "WeChat Browser";
  } else if (userAgent.includes("baidu")) {
    browserType = "Baidu Browser";
  } else if (userAgent.includes("ucbrowser")) {
    browserType = "UC Browser";
  } else if (userAgent.includes("quark")) {
    browserType = "Quark";
  } else if (userAgent.includes("mqqbrowser")) {
    browserType = "QQ Browser";
  } else if (userAgent.includes("firefox")) {
    browserType = "Firefox";
  } else if (userAgent.includes("opera") || userAgent.includes("opr/")) {
    browserType = "Opera";
  } else if (userAgent.includes("edge")) {
    browserType = "Edge";
  } else if (userAgent.includes("silk")) {
    browserType = "Silk";
  } else if (userAgent.includes("electron")) {
    browserType = "Electron";
  } else if (userAgent.includes("chrome")) {
    browserType = "Chrome";
  } else if (userAgent.includes("safari") && !userAgent.includes("chrome")) {
    browserType = "Safari";
  }
  return browserType;
}

export function formattedTime(timestamp = Date.now()) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * 获取URL参数
 * @param {String} name
 * @returns
 */
export const QueryString = (name: string): string | null => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
};

/**
 * 获取cookie
 * @param {*} cname
 * @returns
 */
export const GetCookie = (cname: string): string => {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export function GetUrlParams(): Record<string, string> {
  const url: string = window.location.href;
  const params: Record<string, string> = {};
  const search: string | undefined = url.split("?")[1];
  if (search) {
    const pairs: string[] = search.split("&");
    pairs.forEach((pair: string) => {
      const [key, value]: string[] = pair.split("=");
      params[key] = decodeURIComponent(value);
    });
  }
  return params;
}

export function getImgUrl(img: string) {
  return new URL(`../assets/images/${img}`, import.meta.url).href;
}

export function secondsToHms(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // 在数字小于10时在前面补0
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return [formattedHours, formattedMinutes, formattedSeconds];
}

export function secondsToHms2(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // 在数字小于10时在前面补0
  const formattedHours = hours ? hours + "小时" : "";
  const formattedMinutes = minutes ? minutes + "分钟" : "";
  const formattedSeconds = remainingSeconds ? remainingSeconds + "秒" : "";

  return formattedHours + formattedMinutes + formattedSeconds;
}

export function copyToClipboard(text: string): boolean {
  const elem = document.createElement("textarea");
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  const result = document.execCommand("copy");
  document.body.removeChild(elem);
  return result;
}

// 隐藏中间四位
export function hideMiddleFourDigits(account: string) {
  if (account.length <= 7) return account; // 如果账号长度不足，直接返回原值
  const start = account.substring(0, 3);
  const end = account.substring(account.length - 4);
  const middle = "*".repeat(account.length - 7); // 根据中间长度确定星号的个数
  return start + middle + end;
}

// 只保留首位
export function hideExceptFirst(account: string) {
  if (account.length <= 1) return account; // 如果字符串长度不足，直接返回原值
  const firstChar = account.charAt(0);
  const hiddenPart = "*".repeat(account.length - 1); // 根据长度决定星号个数
  return firstChar + hiddenPart;
}

// 保存一位小数，分割小数点
export function splitNumber(num: any) {
  if (!num) num = 0.0;
  const arr = num.toString().split(".");
  return [arr[0], arr[1][0] || 0];
}

export function openExternalUrl(url: string) {
  if (useGlobalStore().uapp) {
    (window as any).uni.postMessage({
      data: {
        actionName: "uni_openPage",
        params: {
          url: url,
        },
      },
    });
  } else {
    window.open(url, "_blank");
  }
}

export const uniPostMessage = (name: string, params: any = {}) => {
  return new Promise((resolve, reject) => {
    const windowName: string = `window_${name}_${new Date().getTime()}`;
    // console.log("发送：", params.url || name);
    (window as any)[windowName] = (res: any) => {
      (window as any)[windowName] = null;
      // console.log("收到：", params.url || name, res);
      resolve(res);
    };
    setTimeout(() => {
      reject();
    }, 2000);
    (window as any).uni.postMessage({
      data: {
        actionName: name,
        callBackFunctionName: windowName,
        params: params,
      },
    });
  });
};

// 位置唯一识别号作为文件名
export const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(36);
    }
  );
  return uuid;
};

<template>
  <div class="h-860 rd-20 overflow-hidden relative bg-common-pop px-52">
    <b class="fc text-32 mt-30">实名认证</b>
    <div class="text-24 lh-40 mt-36 mb-10">
      <b class="mb-40 block">亲爱的玩家，您当前登录的账号未进行实名认证登记。</b>
      根据我国《关于防止未成年人沉迷网络游戏的通知》，网络用户均须登记实名信息。实名后不可修改，请谨慎填写。
    </div>
    <div class="text-20 c-#949494 mt-40">
      *以下信息仅用于实名登记，我们将严格保护您的信息安全
    </div>
    <div class="mt-40">
      <div class="w-508 h-76 bg-#fff rd-12 text-center m-auto">
        <input
          v-model="name"
          type="text"
          placeholder="请输入您的姓名"
          class="w-full h-full text-center"
        />
      </div>
      <div class="w-508 h-76 bg-#fff rd-12 text-center m-auto mt-28">
        <input
          v-model="id_no"
          type="text"
          placeholder="请输入您的身份证号"
          class="w-full h-full text-center"
        />
      </div>
    </div>
    <div class="c-#F16C6C text-20 absolute left-0 text-center w-full mt-8">{{ msg }}</div>
    <div @click="handleSubmit" class="w-256 h-76 btn-1 mt-86 m-auto">确定</div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { apiCardIdAuth } from "@/api";
import { useUserStore } from "@/store/modules/user";
import { useGlobalStore } from "@/store/modules/global";
import { showToast } from "vant";

const name = ref("");
const id_no = ref("");
const msg = ref("");

function handleSubmit() {
  if (!name.value) {
    msg.value = "请输入您的姓名";
    return;
  }
  if (!id_no.value) {
    msg.value = "请输入您的身份证号";
    return;
  }
  const uid: any = useUserStore().profile?.user_info?.uid;
  apiCardIdAuth(name.value, id_no.value, uid)
    .then((res) => {
      console.log('apiCardIdAuth', res);
      showToast("实名认证成功");
      useUserStore().getUserInfo();
      useGlobalStore().setRealName(false);
    })
    .catch((err) => {
      console.log(err);
      msg.value = err;
    });
}
</script>

<style lang="scss" scoped></style>

import {
  apiProfile,
  apiQuickLogin,
  apiRefresh,
  apiThirdLogin,
} from "@/api";
import MessageBus from "@/utils/MessageBus";
import { defineStore } from "pinia";

interface IUserState {
  profile: IUserInfo;
  points: number;
  registFlag: boolean;
  isLogin: boolean;
  refreshFetching: boolean;
}

export const useUserStore = defineStore({
  id: "app-user",
  state: (): IUserState => ({
    profile: {},
    points: 0,
    registFlag: false,
    isLogin: false,
    refreshFetching: false,
  }),
  actions: {
    login(mobile: string, code: string) {
      return new Promise<any>((resolve, reject) => {
        apiQuickLogin({
          mobile: mobile,
          code: code,
        })
          .then((res) => {
            this.loginSuccess(res);
            // 新用户is_register
            if (res.is_register) {
              this.registFlag = true;
            } else {
              this.registFlag = false;
            }
            resolve(res);
          })
          .catch(() => {
            reject();
          });
      });
    },
    thirdLogin(mobile: string, sms_code: string, bind_qr_key: string) {
      return apiThirdLogin({
        mobile,
        sms_code,
        bind_qr_key,
        type: "wxPublicBind",
        driver: "weixin_public",
      })
        .then((res) => {
          this.loginSuccess(res);
          // 新用户is_register
          if (res.is_register) {
            this.registFlag = true;
          } else {
            this.registFlag = false;
          }
        })
        .catch(() => {});
    },
    loginSuccess(res: ILoginData) {
      this.isLogin = true;
      localStorage.setItem("refresh_token", res.refresh_token);
      sessionStorage.setItem("token", res.token);
      sessionStorage.setItem("expires", res.expire.toString());
      this.getUserInfo();
      this.checkToken();
    },
    checkToken() {
      const step = 1000 * 3;
      const timeout = setTimeout(() => {
        const e = sessionStorage.getItem("expires");
        let expires = e ? parseFloat(e) : 0;
        expires -= 3;
        if (expires > 60 * 60) {
          sessionStorage.setItem("expires", expires.toString());
          this.checkToken();
        } else {
          clearTimeout(timeout);
          try {
            this.refreshToken();
          } catch (error) {}
        }
      }, step);
    },
    // 刷新token
    refreshToken() {
      return new Promise((resolve, reject) => {
        const refresh_token = localStorage.getItem("refresh_token");
        if (refresh_token) {
          this.refreshFetching = true;
          apiRefresh({
            refresh_token: refresh_token,
          })
            .then((res) => {
              this.loginSuccess(res);
              this.refreshFetching = false;
              MessageBus.emit("refreshToken");
              resolve(res);
            })
            .catch((err) => {
              this.refreshFetching = false;
              console.log("refreshToken error", err);
              reject();
            });
        } else {
          reject();
        }
      });
    },
    autoLogin() {
      const num = sessionStorage.getItem("expires");
      const expires = num && parseFloat(num) > 0 ? parseFloat(num) : 0;
      const refresh_token = localStorage.getItem("refresh_token");
      const token = sessionStorage.getItem("token");
      if (token && expires > 60 * 60) {
        this.isLogin = true;
        this.getUserInfo();
        this.checkToken();
      } else if (refresh_token) {
        this.refreshToken();
      } else {
        console.log("用户未登录");
      }
    },
    logOut() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("expires");
      sessionStorage.removeItem("recharge_amount");
      localStorage.removeItem("refresh_token");
      this.profile = {};
      this.isLogin = false;
    },
    getUserInfo() {
      if (sessionStorage.getItem("token")) {
        apiProfile()
          .then((res) => {
            this.profile = res;
            this.points = Number(Number(res.user_info!.points).toFixed(1)) || 0;
          })
          .catch(() => {});
      }
    },
  },
});

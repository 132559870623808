<template>
  <div class="fixed top-0 left-0 w-full h-100vh bg z-10">
    <div class="fac h-88 pl-24">
      <a @click="back" class="mr-24">
        <img
          class="w-48 h-48"
          src="@/assets/images/common/icon-back.png"
          alt="icon"
        />
      </a>
      <a-dropdown v-model:open="dropdownShow" :trigger="['click']">
        <div class="w-528 h-64 bg-#fff shadow-2 rd-8 fac px-20">
          <img
            class="w-40 h-40"
            src="@/assets/images/common/search.png"
            alt="icon"
          />
          <input
            v-model="searchValue"
            type="text"
            class="w-full h-full pl-20 pr-10"
            placeholder="搜索"
          />
        </div>
        <template #overlay>
          <div
            v-if="searchValue"
            class="w-596 max-h-382 overflow-y-auto bg-#fbfbfb rd-6 shadow-2 b-1 b-solid b-#000 b-op-18 px-22 py-20"
          >
            <div
              @click="onSearch(item, searchValue)"
              v-for="(item, index) in typeList"
              :key="index"
              class="fac c-#949494 text-24 h-60 hover:bg-#EFEFEF rd-8 px-10 pointer"
            >
              <img
                class="w-32 h-32"
                src="@/assets/images/common/icon-enter.png"
                alt=""
              />
              在 <b class="mx-4">{{ item }}</b> 搜索
              <span class="c-#333 w-320 ellipsis">"{{ searchValue }}"</span>
            </div>
          </div>
        </template>
      </a-dropdown>
      <div @click="onSearch('商城', searchValue)" class="text-24 ml-34">
        搜索
      </div>
    </div>

    <div v-if="!searchType" class="mt-52 mx-52">
      <div class="fab mb-20">
        <div class="fac">
          <img
            class="w-32"
            src="@/assets/images/common/icon-history.png"
            alt="icon"
          />
          历史搜索
        </div>

        <img
          @click="clearSearch"
          class="w-32"
          src="@/assets/images/common/icon-del.png"
          alt="icon"
        />
      </div>
      <!-- 暂无相关搜索 -->
      <div class="h-300">
        <div
          v-if="usePreloadStore().searchList.length == 0"
          class="w-204 h-62 bg-#e8e8e8 rd-6 fc c-#333 c-op-50 m-auto mt-56"
        >
          暂无相关搜索
        </div>
        <div v-else class="flex flex-wrap gap-8 w-700">
          <div
            @click="clickSearchItem(item)"
            v-for="(item, index) in usePreloadStore().searchList"
            :key="index"
            class="px-20 h-50 bg-#ddd rd-6 lh-50 c-#333 c-op-50 text-24 ellipsis max-w-320"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <!-- 大家都在玩 -->
      <div class="fac mb-10 mt-18 text-28 c-#949494">大家都在玩</div>
      <div class="flex flex-wrap gap-x-20 gap-y-28 pb-10">
        <div
          @click="goDetail(item.game_product_id, item.id)"
          v-for="(item, index) in usePreloadStore().hotGameList"
          :key="index"
          class="fac rd-2 pr-6 pointer hover:bg-#EFEFEF"
        >
          <img
            class="w-124 h-64 object-cover rd-4 mr-16"
            :src="item.game_product.list_img"
            alt="icon"
          />
          <div
            class="text-24 w-500 whitespace-nowrap overflow-hidden text-ellipsis"
          >
            {{ item.game_name }}
          </div>
        </div>
      </div>
    </div>

    <!-- 搜索结果 -->
    <div v-else class="mt-36">
      <div class="c-#949494 pl-24 mb-8">
        在 <b class="c-#333">{{ searchType }}</b> 中搜索到
      </div>

      <div v-if="list?.length == 0" class="text-center m-auto">
        <img
          class="m-auto mt-120 mb-16 w-228"
          src="@/assets/images/common/nodata.png"
          alt="icon"
        />
        <div class="mb-30 c-#949494">暂无相关结果</div>
      </div>

      <div v-if="searchType == '商城'">
        <div
          class="bg-#e5e5e5 p-24 mb-8"
          v-for="(item, index) in list"
          :key="index"
        >
          <gameItem
            @click="goDetail(item.game_product_id, item.id)"
            :item="item"
          ></gameItem>
        </div>
      </div>

      <div v-if="searchType == '交易社区'">
        <div
          v-if="list?.length > 0"
          class="flex flex-wrap gap-20 p-24 bg-#e5e5e5"
        >
          <div
            @click="goCommunityDetail(item.product_id, item.game_id)"
            class="w-340 rd-12 overflow-hidden bg-#fff"
            v-for="(item, index) in list"
            :key="index"
          >
            <img
              class="w-340 h-192 object-cover"
              :src="item.game_product.list_img"
              alt="icon"
            />
            <div class="text-24 pt-20 px-16 font-bold ellipsis">
              {{ item.title }}
            </div>
            <div class="fab p-14">
              <div class="h-40 px-18 c-#949494 text-20 bg-#f3f3f3 fc rd-8">
                积分
                <b class="text-24 c-#333 ml-10">{{
                  Number(item.price).toFixed(1)
                }}</b>
              </div>
              <div class="text-20 c-#949494">
                {{ item.game.market_num }}在售
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="searchType == '存档' || searchType == 'MOD'">
        <div
          v-if="list?.length > 0"
          class="flex flex-wrap gap-20 p-24 bg-#e5e5e5"
        >
          <div
            @click="goArchiveMod(item)"
            class="w-340 rd-12 overflow-hidden bg-#fff"
            v-for="(item, index) in list"
            :key="index"
          >
            <img
              class="w-340 h-192 object-cover"
              :src="item.cover"
              alt="icon"
            />
            <div class="text-24 h-72 pt-20 px-16 ellipsis">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiArchiveModList, apiGame, apiMarket } from "@/api";
import { usePreloadStore } from "@/store/modules/preload";
import { ref } from "vue";
import { useRouter } from "vue-router";
import gameItem from "@/components/common/gameItem.vue";
import { useGlobalStore } from "@/store/modules/global";

const searchValue = ref<string>("");
const dropdownShow = ref(false);
const typeList = ref(["商城", "交易社区", "存档", "MOD"]);
const searchType = ref("");
const list = ref();

const router = useRouter();

const emit = defineEmits(["close"]);

setTimeout(() => {
  apiGame({ page: 1, page_size: 10, sort: "hot", ttype: "search" })
    .then((res: IGame) => {
      usePreloadStore().setHotGameList(res.data);
    })
    .catch(() => {});
}, 1000);

function back() {
  useGlobalStore().setShowSearch(false);
}

function clickSearchItem(item: string) {
  const arr = item.split(":");
  onSearch(arr[0], arr[1]);
}

function onSearch(type: string, str: string) {
  searchType.value = type;
  dropdownShow.value = false;
  str = str.trim();
  if (str === "") return;

  let arr: any = usePreloadStore().searchList;
  arr.unshift(`${type}:${str}`); // 使用unshift方法在数组前添加一位
  usePreloadStore().setSearchList([...new Set(arr)]);

  switch (type) {
    case "交易社区":
      apiMarket({ page: 1, page_size: 20, product_name: str }).then(
        (res: any) => {
          console.log("apiMarket", res);
          list.value = res.data;
        }
      );
      break;
    case "存档":
      apiArchiveModList({
        page: 1,
        page_size: 20,
        type: 0,
        game_name: str,
      }).then((res: IGame) => {
        list.value = res.data;
      });
      break;
    case "MOD":
      apiArchiveModList({
        page: 1,
        page_size: 20,
        type: 1,
        game_name: str,
      }).then((res: IGame) => {
        list.value = res.data;
      });
      break;
    default:
      //   router.push(`/shop/search?search=${str}`);
      apiGame({ page: 1, page_size: 20, keyword: str }).then((res: any) => {
        list.value = res.data;
      });
      break;
  }
  //   searchValue.value = "";
}

const goDetail = (game_product_id: number, game_id: number) => {
  emit("close");
  router.push(
    `/shop/shopDetail?game_product_id=${game_product_id}&game_id=${game_id}`
  );
};

const goCommunityDetail = (game_product_id: number, game_id: number) => {
  emit("close");
  router.push(
    `/communityDetail?game_product_id=${game_product_id}&game_id=${game_id}`
  );
};

function goArchiveMod(item: any) {
  emit("close");
  useGlobalStore().setArchiveModItem(item);
  router.push(`/toolDetail`);
}

function clearSearch() {
  usePreloadStore().setSearchList([]);
}
</script>

<style lang="scss" scoped>
.bg {
  background: #f0f0f0 url('@/assets/images/common/bg-search-bottom.png') center bottom no-repeat;
  background-size: 100% auto;
}
</style>

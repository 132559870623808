import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useGlobalStore } from "@/store/modules/global";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/home.vue"),
    meta: { tabbar: true },
  },
  {
    path: "/tool",
    name: "tool",
    component: () => import("@/views/home/tool.vue"),
    meta: {},
  },
  {
    path: "/toolDetail",
    name: "toolDetail",
    component: () => import("@/views/home/toolDetail.vue"),
    meta: {},
  },
  {
    path: "/choiceness",
    name: "choiceness",
    component: () => import("@/views/home/choiceness.vue"),
    meta: {},
  },
  {
    path: "/information",
    name: "information",
    component: () => import("@/views/home/information.vue"),
    meta: {},
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("@/views/shop/shop.vue"),
    meta: { keepAlive: true, tabbar: true },
  },
  {
    path: "/shop/shopDetail",
    name: "shopDetail",
    component: () => import("@/views/shop/shopDetail.vue"),
    meta: {},
  },
  {
    path: "/community",
    name: "community",
    component: () => import("@/views/community/community.vue"),
    meta: { keepAlive: true, tabbar: true },
  },
  {
    path: "/communityList",
    name: "communityList",
    component: () => import("@/views/community/communityList.vue"),
    meta: {},
  },
  {
    path: "/communityDetail",
    name: "communityDetail",
    component: () => import("@/views/community/communityDetail.vue"),
    meta: {},
  },
  {
    path: "/communityStat",
    name: "communityStat",
    component: () => import("@/views/community/communityStat.vue"),
    meta: {},
  },
  {
    path: "/communityDeal",
    name: "communityDeal",
    component: () => import("@/views/community/communityDeal.vue"),
    meta: {},
  },
  {
    path: "/mine",
    name: "mine",
    component: () => import("@/views/mine/mine.vue"),
    meta: { keepAlive: true, tabbar: true },
  },

  {
    path: "/setting",
    name: "setting",
    component: () => import("@/views/mine/setting.vue"),
    meta: {},
  },
  {
    path: "/logoff",
    name: "logoff",
    component: () => import("@/views/mine/logoff.vue"),
    meta: {},
  },
  {
    path: "/userinformation",
    name: "userinformation",
    component: () => import("@/views/mine/information.vue"),
    meta: {},
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import("@/views/mine/aboutus.vue"),
    meta: {},
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: () => import("@/views/protocol/userAgreement.vue"),
    meta: { noLogin: true },
  },
  {
    path: "/payAgreement",
    name: "payAgreement",
    component: () => import("@/views/protocol/payAgreement.vue"),
    meta: { noLogin: true },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import("@/views/protocol/privacyPolicy.vue"),
    meta: { noLogin: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
    meta: { noLogin: true },
  },
  {
    path: "/archival",
    name: "archival",
    component: () => import("@/views/mine/archival.vue"),
    meta: { noLogin: true },
  },
  {
    path: "/nickname",
    name: "nickname",
    component: () => import("@/views/mine/nickname.vue"),
    meta: { noLogin: true },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/mine/help.vue'),
    meta: { noLogin: true },
  },
  {
    path: '/help/:id',
    name: 'helpDetail',
    component: () => import('@/views/mine/helpDetail.vue'),
    meta: { noLogin: true },
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // 记录滚动条位置
  if (from.meta.keepAlive) {
    const scrollTop = (document.getElementById("layout") as HTMLElement)
      .scrollTop;
    useGlobalStore().setSavedPosition(String(from.name), scrollTop);
  }
  if (to.meta.keepAlive) {
    const scrollTop = useGlobalStore().savedPosition[String(to.name)];
    setTimeout(() => {
      (document.getElementById("layout") as any).scrollTop = scrollTop;
    }, 250);
  } else {
    setTimeout(() => {
      (document.getElementById("layout") as any).scrollTop = 0;
    }, 250);
  }

  // 登录权限控制
  if (!to.meta.noLogin && !localStorage.getItem("refresh_token")) {
    router.push("/login");
  }

  // 控制tabbar显示
  if (to.meta.tabbar) {
    useGlobalStore().setTabbar(true);
  } else {
    useGlobalStore().setTabbar(false);
  }

  next();
});

export default router;

import { usePreloadStore } from "@/store/modules/preload";
import { ref, watch } from "vue";
export function useRegion() {
  const Region: any = ref();

  // 区服
  watch(
    () => usePreloadStore().regionList,
    () => {
      usePreloadStore().regionList.forEach((item: any) => {
        Region[item.value] = item.key;
      });
    },
    { immediate: true }
  );

  return { Region };
}

import { httpGet, httpPost, httpPut } from "@/utils/Request";

export const apiGetAuthToken = (
  params: IGetAuthTokenParams
): Promise<IGetAuthTokenResponse> => {
  return httpGet("/auth/token", params);
};

export const apiLogin = (params: ILoginParams): Promise<ILoginData> => {
  return httpPost("/api/login", params);
};

export const apiQuickLogin = (params: ILoginParams): Promise<ILoginData> => {
  return httpPost("api/quickLogin", params);
};

export const apiThirdLogin = (
  params: IThirdLoginParams
): Promise<ILoginData> => {
  return httpPost("/api/thirdLogin", params);
};

export const apiRefresh = (params: IRefreshParams): Promise<ILoginData> => {
  return httpGet("/api/refresh", params);
};

export const apiSendCode = (params: ISendCodeParams): Promise<ILoginData> => {
  return httpPost("/api/sendCode", params);
};

// 获取个人资料
export const apiProfile = (): Promise<IUserInfo> => {
  return httpGet("api/profile", {});
};

// 游戏列表
export const apiGame = (params: IGameParams): Promise<IGame> => {
  return httpGet("api/game", params);
};

export const apiProduct = (id: string): Promise<IProductData> => {
  return httpGet("api/gameProduct/" + id, {});
};

// 字典
export const apiDict = (key: string): Promise<IDictData> => {
  return httpGet("api/dict", { key: key });
};

// 全局配置
export const apiConfig = (key: string): Promise<IConfigData> => {
  return httpGet("api/config", { key: key });
};

// 商城下单
export const apiCreateGameOrder = (
  params: ICreateGameOrderParams
): Promise<any> => {
  return httpPost("api/createGameOrder", params);
};

// 社区下单
export const apiMarketOrder = (market_id: string): Promise<any> => {
  return httpPost("api/createMarketOrder", { market_id: market_id });
};

// 创建兜币充值订单
export const apiCreatePointsOrder = (
  params: ICreatePointsOrderParams
): Promise<any> => {
  return httpPost("api/createPointsOrder", params);
};

// 查询充值订单
export const apiQueryOrder = (params: IQueryOrderParams): Promise<any> => {
  return httpGet("api/queryOrder", params);
};

// 在售兜币充值方案
export const apiPoints = (): Promise<any> => {
  return httpGet("api/points", {});
};

// 获取推荐位列表
export const apiRecommend = (name: string): Promise<any> => {
  return httpGet("api/recommend", { name: name });
};

// 获取游戏时间轴
export const apiGameTimeline = (): Promise<any> => {
  return httpGet("api/gameTimeline", {});
};

// 系统消息通知
export const apiLatestDynamic = (params: any): Promise<any> => {
  return httpGet("api/latestDynamic", {
    limit: 50,
    action: params,
  });
};

// 获取筛选游戏tag
export const apiTag = (): Promise<any> => {
  return httpGet("api/tag", { page: 1, page_size: 30, is_menu: 1 });
};

// 账号交易市场列表
export const apiMarket = (params: IMarketParams): Promise<any> => {
  return httpGet("api/market", params);
};

// 账号市场上下架操作
export const apiMarketTrade = (params: IMarketTradeParams): Promise<any> => {
  return httpPost("api/marketTrade", params);
};

// 获取用户游戏库
export const apiUserGame = (params: IUserGameParams): Promise<any> => {
  return httpGet("api/userGame", params);
};

// 游戏资产回收
export const apiGameRecycle = (user_game_id: string): Promise<any> => {
  return httpPost("api/gameRecycle", { user_game_id: user_game_id });
};

// 用户订单列表
export const apiGameOrderList = (
  params: IGameOrderListParams
): Promise<any> => {
  return httpGet("api/gameOrderList", params);
};

// 充值列表
export const apiPointsOrderList = (params: IPageParams): Promise<any> => {
  return httpGet("api/pointsOrderList", params);
};

// 出售列表
export const apiSaleOrderList = (params: IPageParams): Promise<any> => {
  return httpGet("api/saleOrderList", params);
};

// 提取独享账号
export const apiExtractGameAccount = (
  order_no: string,
  code?: string
): Promise<any> => {
  return httpGet("api/extractGameAccount", { order_no, code });
};

// 游戏资产回收
// export const apiWithdrawAccount = (
//   realname: string,
//   account: string
// ): Promise<any> => {
//   return httpPost("api/withdrawAccount", {
//     type: 1,
//     realname: realname,
//     account: account,
//   });
// };

// 游戏资产回收
export const apiWithdraw = (params: IWithdrawParams): Promise<any> => {
  return httpPost("api/withdraw", params);
};

export const apiUpdateProfile = (
  params: IUpdateProfileParams
): Promise<any> => {
  return httpPut("api/profile", params);
};

// 策略上传
export const apiPolicy = (type: string): Promise<PolicyData> => {
  return httpGet("api/upload/policy", { type });
};

// 存档
export const apiArchiveModList = (
  params: IArchiveModListParams
): Promise<any> => {
  return httpGet("api/archiveModList", params);
};

// 身份证实名认证
export const apiCardIdAuth = (
  name: string,
  id_no: string,
  uid: string
): Promise<any> => {
  return httpGet("api/cardIdAuth", { name, id_no, uid });
};

// 获取微信公众号登录临时二维码
export const apiWechatPublicQrcode = (code: string): Promise<any> => {
  return httpPost("api/wechatPublicQrcode", { code: code });
};

// 查询二维码登录状态
export const apiQueryWechatLogin = (key: string): Promise<any> => {
  return httpGet("api/queryWechatLogin", { key });
};

// 游戏图表
export const apiGameChart = (game_id: string): Promise<any> => {
  return httpGet("api/gameChart", { game_id });
};

// 启动游戏
export const apiLaunchGame = (user_game_id: string): Promise<any> => {
  return httpPost("api/launchGame", { user_game_id });
};

// 打点上报
export const apiCollectEvent = (params: any): Promise<any> => {
  return httpPost("api/collect", {
    report_type: "event",
    data: {
      userAgent: navigator.userAgent,
      ...params,
    },
  });
};

// 获取常见问题
export const apiArticle = (params: IArticle): Promise<any> => {
  return httpGet("api/article", params);
};

export const apiArticleDetail = (id: string): Promise<IProductData> => {
  return httpGet("api/article/" + id, {});
};

// 加速器列表
export const apiAccelerator = (): Promise<any> => {
  return httpGet("api/accelerator", {});
};

export const apiLogoff = (): Promise<any> => {
  return httpPost("api/logoff", {});
};

export const apiSystemStats = (): Promise<any> => {
  return httpGet("api/systemStats", {});
};

// 交易统计
export const apiTopList = (params: TopListParams): Promise<TopListData[]> => {
  return httpGet("api/toplist", params);
};

// 资讯互动
export const apiAarticleInteraction = (
  id: string,
  params: InteractionParams
) => {
  return httpPost("api/articleAction/" + id, params);
};

// 问题反馈
export const apiFeedback = (params: FeedbackParams) => {
  return httpPost("api/feedback", params);
};

// 存档Mod交互 /api/gameArchiveAction
export const apiArchiveAction = (data: ArchiveActionParams) => {
  return httpPost("api/gameArchiveAction", data);
};
